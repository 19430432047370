@import '~bootstrap/scss/bootstrap.scss';

//GENERAL
.container {
    margin: 0;
    .image-wrapper {
        position: relative;
        height: 100%;
        width: 100%;
        padding: 0;
        margin: 0;

        #intro-image {
            position: relative;
        }
    }
}

@media (max-width: 720px) {
    .container{
        .image-wrapper{
            #intro-image {
                width: 100%;
            }
        }
    }

}