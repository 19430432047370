@import '~bootstrap/scss/bootstrap.scss';

//GENERAL
.footer-section{
    display: flex;
    align-items: flex-end;
    .footer-content{
        .blockquote {
            p {
                font-size: .7rem;
                text-align: center;
                font-style: italic;
            }
            cite {
                float: right;
            }
        }
        .links{
            li {
                float: right;
                display: inline;
                padding: 10px;
                transition: all .75s ease;
                a {
                    text-decoration: none;
                    text-decoration-color: white;
                    transition: all .75s ease;
                }
                &:hover {
                    text-decoration: underline;
                    text-decoration-color: white;
                    transform: translate(0, -10px);
                }
            }
        }
    }

}