@import '~bootstrap/scss/bootstrap.scss';

//GENERAL
.navbar {
    transition: background-color 0.75s ease, opacity .75s ease, filter .75s ease;
    background-color: black;
    top: 0;
    right: 0;
    z-index: 1;
    position: sticky !important;
    color: white;
    filter: blur(2px);
    opacity: 30%;
    &:hover{
        opacity: 100%;
        filter: blur(0);
    }
    .nav-link {
        padding: 1rem;
        float: right;
        display: inline;
        padding: 10px;
        color: white;
        &:hover {
            text-decoration: underline;
            color:white;
        }
        &.active{
            color: grey;
        }
    }
    .navbar-brand {
        margin-right: auto;
    }
    
    .navbar-nav {
        align-items: flex-start;
    }
    
    
}

//MEDIA QUERIES
@media (max-width: 720px) {
    .navbar{
        opacity: 100%;
        filter: blur(0);
        position: sticky !important;
        .navbar-toggler-icon{
            color: white;
        }
    }
}
