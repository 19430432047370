@import '~bootstrap/scss/bootstrap.scss';

//GENERAL
.input-group {
    .message-area {
        border-bottom: white 2px solid;
        border-left: none;
        border-right: none;
        border-top: none;
        background-color: black;
        color: white;
        resize: none;
    }

    .input-group-text {
        border-bottom: white 2px solid;
        border-left: white 2px solid;
        border-right: none;
        border-top: none;
        background-color: black;
        color: rgb(155, 152, 152);
        resize: none;
    }
}

#name,
#email,
#phone-number {
    border: none;
    background-color: black;
    color: white;
    resize: none;
    border-bottom: white 2px solid;
    border-left: white 2px solid;
    border-right: none;
    border-top: none;
}

#name::placeholder,
#email::placeholder,
#phone-number::placeholder {
    color: rgb(155, 152, 152);
}

.submit-button {

    align-items: center;    
    background-color: black;
    border-radius: 42px;
    border: none;
    box-shadow: rgba(57, 31, 91, 0.24) 0 2px 2px, rgba(179, 132, 201, 0.4) 0 8px 12px;
    padding: 6px 10px;
    p {
        color: white;
        text-shadow: rgba(255, 255, 255, 0.4) 0 0 4px, rgba(255, 255, 255, 0.2) 0 0 12px, rgba(57, 31, 91, 0.6) 1px 1px 4px, rgba(57, 31, 91, 0.32) 4px 4px 16px;
    }

}