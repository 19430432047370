@import '~bootstrap/scss/bootstrap.scss';

//GENERAL
body {
  background-color: black;
  background: radial-gradient(circle at 100%, #312e2f, #222222 50%, #000000 75%, #333 75%);
  width: 100vw;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.container {
  max-width: 100%;
  max-height: 100%;

  &.full-body {
    align-items: center;
    justify-content: center;
    padding: 0;
    max-width: 100vw;
  }

  #welcome-view {
    animation: fadeIn 4s forwards;
  }
}

@keyframes fadeIn {
  0% {
      opacity: 0;
      transform: translate(0, -10);
  }

  100% {
      opacity: 1;
      transform: translate(0, 0);
  }
}
