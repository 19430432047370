@import '~bootstrap/scss/bootstrap.scss';

//GENERAL
.welcome-container{
    height: 100vh;
    width: 100vw;
    background-image: url('../../assets/backgrounds/HeroBanner.png');
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    .text-white{
        .welcome-title,
        .welcome-subtitle{
            color: white;
            text-shadow: 3px 2px rgb(0, 0, 0);
            pointer-events: none;
        }
        .welcome-title{
            font-weight: bolder;
        }
        .resume-button{
            color:white;
            display: inline-block;
            h5{
                color: white;
                transition: all .75s ease;
                text-decoration-color: white;
                transform: translate(0, 12px);
                text-shadow: 3px 2px rgb(0, 0, 0);
                &:hover{
                    transform: translate(0, 0px);
                    color: rgb(20, 191, 197); 
                }
                &:active{
                    scale: .6;
                }
            }
        }
    }
}

