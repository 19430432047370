@import '~bootstrap/scss/bootstrap.scss';

// GENERAL
#main-projects-container {
    .section-subtitle {
        text-align: center;
        justify-content: center;
        color: white;
        text-shadow: 2px 2px rgb(0, 0, 0);
    }

    .no-skills-section {
        color: white;
        text-align: end;
        height: 90vh;

        .no-skills-text{
            text-shadow: 2px 2px rgb(0, 0, 0);
        }
        /* CSS */
        .show-all-button {
            transition: color .3s ease;
            align-items: center;
            background-image: linear-gradient(rgba(255, 255, 255, 0.84), rgba(54, 204, 227, 0.84) 50%);
            border-radius: 42px;
            border: none;
            box-shadow: rgba(57, 31, 91, 0.24) 0 2px 2px, rgba(179, 132, 201, 0.4) 0 8px 12px;
            padding: 12px 18px;
            text-shadow: rgba(255, 255, 255, 0.4) 0 0 4px, rgba(255, 255, 255, 0.2) 0 0 12px, rgba(57, 31, 91, 0.6) 1px 1px 4px, rgba(57, 31, 91, 0.32) 4px 4px 16px;
            &:hover{
                color: white;
            }
        }
    }

    #projects-carousel {

        .carousel-control-prev,
        .carousel-control-next {
            background: none;
            margin: 0 -5%;
            height: auto;
            text-shadow: 2px 2px rgb(0, 0, 0);
            opacity: 1;
        }

        .sr-only {
            vertical-align: baseline;
            text-shadow: 2px 2px rgb(0, 0, 0);
            font-weight: bolder;
        }

        .preview-popup {
            opacity: 0;
            color: red;
            font-weight: bolder;
            text-shadow: 2px 2px rgb(0, 0, 0);
            transition: all .5s ease;

            &.preview-revealed {
                opacity: 1;
            }
        }
    }

    #skills-section,
    #projects-section {
        display: flex;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
    }

    #skills-section {
        display: flex;
        height: 90vh;
        .button-skills-list {
            height: 80vh;
            padding: 0;
            align-items: center;
            justify-content: center;
            margin: 0;
            overflow: scroll;
            .custom-btn {
                font-size: .75rem;
                padding: 3px;
                border: none;
                width: 10em;
                height: 3em;

                &.button-clicked {
                    color: white;
                    box-shadow: 0 0 10px #9ecaed;
                    animation: buttonClick .6s forwards;
                }
            }
        }
        .clear-skills {
            background-image: linear-gradient(rgba(255, 255, 255, 0.84), rgba(54, 204, 227, 0.84) 50%);
            border-radius: 42px;
            border: none;
            box-shadow: rgba(57, 31, 91, 0.24) 0 2px 2px, rgba(179, 132, 201, 0.4) 0 8px 12px;
            padding: 12px 18px;
            
            h4{
                text-shadow: rgba(255, 255, 255, 0.4) 0 0 4px, rgba(255, 255, 255, 0.2) 0 0 12px, rgba(57, 31, 91, 0.6) 1px 1px 4px, rgba(57, 31, 91, 0.32) 4px 4px 16px;
                transition: color .3s ease;
            }
            &:hover{
                h4{
                    color: white;
                }
            }
        }
    }

    #projects-section {
        overflow: hidden;
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: auto auto;
        align-items: center;
        justify-content: center;
        height: 90vh;

        h5 {
            color: #ffffff;
            text-shadow: rgb(0, 0, 0) 3px 0px 0px, rgb(0, 0, 0) 2.83487px 0.981584px 0px, rgb(0, 0, 0) 2.35766px 1.85511px 0px, rgb(0, 0, 0) 1.62091px 2.52441px 0px, rgb(0, 0, 0) 0.705713px 2.91581px 0px, rgb(0, 0, 0) -0.287171px 2.98622px 0px, rgb(0, 0, 0) -1.24844px 2.72789px 0px, rgb(0, 0, 0) -2.07227px 2.16926px 0px, rgb(0, 0, 0) -2.66798px 1.37182px 0px, rgb(0, 0, 0) -2.96998px 0.42336px 0px, rgb(0, 0, 0) -2.94502px -0.571704px 0px, rgb(0, 0, 0) -2.59586px -1.50383px 0px, rgb(0, 0, 0) -1.96093px -2.27041px 0px, rgb(0, 0, 0) -1.11013px -2.78704px 0px, rgb(0, 0, 0) -0.137119px -2.99686px 0px, rgb(0, 0, 0) 0.850987px -2.87677px 0px, rgb(0, 0, 0) 1.74541px -2.43999px 0px, rgb(0, 0, 0) 2.44769px -1.73459px 0px, rgb(0, 0, 0) 2.88051px -0.838247px 0px;
        }

        .project-btn.btn-dark {
            height: 10em;
            width: 20em;
            overflow: hidden;

            &.highlighted {
                border-color: #9ecaed;
                box-shadow: 0 0 10px #9ecaed;
                outline: none;
            }

            .project-image {
                justify-content: center;
                width: auto;
                max-width: 90%;
                height: 80%;
                transition: all .5s ease;
                display: flex;
                position: relative;
                margin: auto;

                &.image-loading {
                    opacity: 0;
                }

                .caption {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0;

                    .main-title {
                        padding: 0;
                        font-size: 1em;

                        p {
                            font-weight: bolder;
                            margin: 0;
                            color: #ffffff;
                            text-shadow: rgb(0, 0, 0) 3px 0px 0px, rgb(0, 0, 0) 2.83487px 0.981584px 0px, rgb(0, 0, 0) 2.35766px 1.85511px 0px, rgb(0, 0, 0) 1.62091px 2.52441px 0px, rgb(0, 0, 0) 0.705713px 2.91581px 0px, rgb(0, 0, 0) -0.287171px 2.98622px 0px, rgb(0, 0, 0) -1.24844px 2.72789px 0px, rgb(0, 0, 0) -2.07227px 2.16926px 0px, rgb(0, 0, 0) -2.66798px 1.37182px 0px, rgb(0, 0, 0) -2.96998px 0.42336px 0px, rgb(0, 0, 0) -2.94502px -0.571704px 0px, rgb(0, 0, 0) -2.59586px -1.50383px 0px, rgb(0, 0, 0) -1.96093px -2.27041px 0px, rgb(0, 0, 0) -1.11013px -2.78704px 0px, rgb(0, 0, 0) -0.137119px -2.99686px 0px, rgb(0, 0, 0) 0.850987px -2.87677px 0px, rgb(0, 0, 0) 1.74541px -2.43999px 0px, rgb(0, 0, 0) 2.44769px -1.73459px 0px, rgb(0, 0, 0) 2.88051px -0.838247px 0px;
                        }
                    }
                }
            }
        }

    }
}

.offcanvas {
    justify-content: center;
    padding: 5px;
    background-image: linear-gradient(white, rgb(63, 62, 62), rgb(63, 62, 62), rgb(63, 62, 62), rgb(63, 62, 62), black);
    overflow: hidden;
    padding: 0;

    h5 {
        color: #ffffff;
        text-shadow: rgb(0, 0, 0) 3px 0px 0px, rgb(0, 0, 0) 2.83487px 0.981584px 0px, rgb(0, 0, 0) 2.35766px 1.85511px 0px, rgb(0, 0, 0) 1.62091px 2.52441px 0px, rgb(0, 0, 0) 0.705713px 2.91581px 0px, rgb(0, 0, 0) -0.287171px 2.98622px 0px, rgb(0, 0, 0) -1.24844px 2.72789px 0px, rgb(0, 0, 0) -2.07227px 2.16926px 0px, rgb(0, 0, 0) -2.66798px 1.37182px 0px, rgb(0, 0, 0) -2.96998px 0.42336px 0px, rgb(0, 0, 0) -2.94502px -0.571704px 0px, rgb(0, 0, 0) -2.59586px -1.50383px 0px, rgb(0, 0, 0) -1.96093px -2.27041px 0px, rgb(0, 0, 0) -1.11013px -2.78704px 0px, rgb(0, 0, 0) -0.137119px -2.99686px 0px, rgb(0, 0, 0) 0.850987px -2.87677px 0px, rgb(0, 0, 0) 1.74541px -2.43999px 0px, rgb(0, 0, 0) 2.44769px -1.73459px 0px, rgb(0, 0, 0) 2.88051px -0.838247px 0px;
    }

    .offcanvas-header {
        color: black;
    }

    .offcanvas-body {
        color: black;
        overflow: hidden;

        #project-shoutout {
            font-weight: bolder;
            text-decoration: none;
        }
    }

    .offcanvas-gif {
        max-width: 80vw;
        max-height: 65vh;

        &.gif-loading {
            opacity: none;
        }
    }

    .offcanvas-button{
        color: black;
        align-items: center;
        background-image: linear-gradient(rgba(255, 255, 255, 0.84), rgba(54, 204, 227, 0.84) 50%);
        border-radius: 42px;
        border: none;
        box-shadow: rgba(57, 31, 91, 0.24) 0 2px 2px, rgba(179, 132, 201, 0.4) 0 8px 12px;
        padding: 6px 10px;
        text-shadow: rgba(255, 255, 255, 0.4) 0 0 4px, rgba(255, 255, 255, 0.2) 0 0 12px, rgba(57, 31, 91, 0.6) 1px 1px 4px, rgba(57, 31, 91, 0.32) 4px 4px 16px;
    }

    .preview-buttons-section {
        display: flex;
        overflow-x: scroll;
        max-width: 100vw;

        .preview-buttons {
            width: 25%;
            /* Make sure four buttons fit into one row (100% / 4) */
            height: auto;
            max-height: 100%;
            background: none;
            border: none;
            transition: filter .5s ease;

            &:hover,
            &:active,
            &:focus {
                background: none;
                border: none;
                filter: drop-shadow(0 0 10px #cbe7ff);
            }

        }
    }
}

.image-loader {
    position: absolute;
}

// MEDIA QUERIES
@media (max-width: 1200px) {
    #main-projects-container {
        max-height: 100vh !important;
        max-width: 100% !important;

        #projects-section {
            overflow: scroll;
            grid-template-columns: auto;
            grid-template-rows: auto auto auto auto;
            row-gap: 10px;
        }

        #skills-section {
            overflow: scroll;

            .button-skills-list {
                overflow: scroll;

                .custom-btn {
                    width: 8em;
                    height: 4em;
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .button-skills-list {
        display: none !important;
    }

    .image-sidebar {
        visibility: hidden !important;
    }
}

// ANIMATIONS
@keyframes buttonClick {
    0% {
        background-color: white;
    }

    100% {
        background-color: black;
    }
}