@import '~bootstrap/scss/bootstrap.scss';

//FONTS
@font-face {
    font-family: 'Oswald';
    src: url('../../assets/fonts/Oswald/Oswald-VariableFont_wght.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    src: url('../../assets/fonts/Montserrat/Montserrat-VariableFont_wght.ttf') format('truetype');
}

//GENERAL
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Oswald', sans-serif;
}

p {
    color: white;
    font-family: 'Montserrat', sans-serif;
    text-shadow: 2px 2px rgb(0, 0, 0);
    font-size: large;
}

//GENERAL
#main-container {

    position: relative;
    overflow-y: auto;
    scroll-snap-type: y mandatory;
    -ms-overflow-style: none;
    scrollbar-width: none;
    width: 100vw;
    height: 100vh;

    #intro-view,
    #projects-view,
    #contact-view {
        background-position: center;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        background-image: url('../../assets/backgrounds/HeroBanner.png');
    }

    .section-block {
        scroll-snap-align: center;
        scroll-behavior: auto;

        .section-title {
            text-align: center;
            justify-content: center;
            color: white;
            margin-bottom: 20px;
            text-shadow: 5px 5px rgb(0, 0, 0);
        }

        &.row {
            height: 100vh;
            align-items: center;
            justify-content: center;

            .section-block-content {
                opacity: .50;
                filter: blur(20px);
                transform: translate(150vw, 0);
                transition: opacity 1.5s ease .5s, filter 1s ease .5s, transform 1.2s ease;
            }

            &.is-visible {
                .section-block-content {
                    opacity: 1;
                    filter: blur(0);
                    transform: translate(0, 0);
                }
            }
        }
    }
}

//MEDIA QUERIES

@media only screen and (max-width: 800px) {

    html,
    body {
        height: 100%;
        margin: 0;
        scrollbar-width: none;
    }

    #main-container {
        background-color: black;
        position: relative;
        scrollbar-width: none;
        max-width: 100vw;

        .fade-in-section {
            &.section-block {
                &.row {
                    border-radius: 0;
                    opacity: .9;
                    transition: opacity .5s ease, filter 1s ease;
                    filter: blur(20px);

                    &.is-visible {
                        opacity: 1;
                        filter: blur(0);
                    }
                }
            }
        }

        #footer-view {

            background-image: url('../../assets/backgrounds/HeroBanner.png');
        }
    }

}